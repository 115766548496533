/* CarouselComponent.css */
.pagination-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2px;
  width: 90%; /* Set a fixed width for the line */
  margin: 20px auto;
  background-color: #ddd;
}

.pagination-part {
  flex: 1;
  height: 100%;
  background-color: #ddd;
}

.pagination-part.active {
  background-color: black;
}

/* Adjust the line width based on the number of visible slides */
.slides-to-show-3 .pagination-line {
  width: 400px; /* Adjust the width for 3 visible slides */
}

.slides-to-show-3 .pagination-part {
  flex: 1;
  height: 100%;
  background-color: #ddd;
}


.slides-to-show-3 .pagination-part.active {
  background-color: black;
}


/* Update your styles */
.custom-slider .slick-slider {
  padding: 0;
  margin: 0;
}

.custom-slider .slick-list {
  margin: 0;
}


.slick-slider {
  overflow: hidden;
  
}
.slick-list { margin: 0 -7px}

.slick-slide > div { padding-right:5px; } 

